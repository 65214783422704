import React, { useState, useCallback, useContext } from 'react';
import styled from 'styled-components';

import { navigate } from 'gatsby';
import { LOADING_STATUS, MESSAGE_TYPES, STYLES } from '../../enums';
import { registerUser } from '../../services/backend/user';
import { CookieContext } from '../../contexts/cookie';
import { UserContext } from '../../contexts/user';
import { MessageContext } from '../../contexts/message';
import useStatus from '../../hooks/useStatus';
import useDisplayErrorMessage from '../../hooks/useDisplayErrorMessage';
import Stack from '../../components/systems/stack';
import Checkbox from '../../components/clickables/checkbox';
import Button from '../../components/clickables/button';
import CustomLink from '../../components/clickables/customLink';
import { getOnLoginRedirect } from '../../utilities/storage';

const Form = styled.form`
  width: 100%;
  @media screen and (min-width: ${STYLES.breakpoints.padWidth}px) {
    width: 400px;
  }
`;

const tag = 'Signup';

const Signup = () => {
  const { setUser } = useContext(UserContext);
  const { acceptedCookies } = useContext(CookieContext);
  const { setMessage, setType } = useContext(MessageContext);
  const displayErrorMessage = useDisplayErrorMessage();
  const { isLoading, setStatus } = useStatus();
  const [givenName, setGivenName] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPw, setPepeatPw] = useState('');
  const [acceptsProtection, setAcceptsProtection] = useState(false);

  const handleCheckboxChange = useCallback(() => {
    setAcceptsProtection(!acceptsProtection);
  }, [acceptsProtection]);

  const handleGivenNameChange = useCallback(event => {
    setGivenName(event.target.value);
  }, []);

  const handleFamilyNameChange = useCallback(event => {
    setFamilyName(event.target.value);
  }, []);

  const handleEmailChange = useCallback(event => {
    setEmail(event.target.value);
  }, []);

  const handlePasswordChange = useCallback(event => {
    setPassword(event.target.value);
  }, []);

  const handleRepeatPwChange = useCallback(event => {
    setPepeatPw(event.target.value);
  }, []);

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault();

      if (!acceptedCookies) {
        setMessage(
          'Bitte akzeptieren Sie unsere Cookie-Bestimmungen. Wir setzen nur essenzielle Cookies ein, um unsere Dienste anbieten zu können.'
        );
        setType(MESSAGE_TYPES.warning);
        return false;
      }

      if (!acceptsProtection) {
        setMessage(
          'Wir benötigen Ihre Zustimmung zu unserem Datenschutz. Bitte bestätigen Sie Ihre Zustimmung.'
        );
        setType(MESSAGE_TYPES.warning);
        return false;
      }

      if (
        !givenName ||
        !familyName ||
        email.length < 2 ||
        !password ||
        !repeatPw
      ) {
        setMessage(
          'Willkommen! Wir benötigen Ihre Email-Adresse, damit wir Ihr Konto zurücksetzen können, falls Sie Ihr Passwort vergessen. Bitte geben Sie einen Namen, oder Spitznamen an, mit dem wir Sie anreden dürfen. Ihr Passwort hilft Ihnen dabei Ihr Konto zu schützen.'
        );
        setType(MESSAGE_TYPES.warning);
        return false;
      }

      if (password !== repeatPw) {
        setMessage(
          'Ups... Die Passwörter stimmen leider nicht überein, bitte überprüfen Sie Ihre Eingaben.'
        );
        setType(MESSAGE_TYPES.warning);
        return false;
      }

      try {
        setStatus(LOADING_STATUS.isLoading);
        setMessage();
        const fetchedUser = await registerUser({
          email,
          password,
          givenName,
          familyName,
        });
        console.gtag('event', 'conversion', {
          send_to: 'AW-408515162/ymbXCNingoACENrk5cIB',
        });
        setStatus(LOADING_STATUS.hasSucceeded);
        setUser(fetchedUser);
        const diseredPath = getOnLoginRedirect();
        navigate(diseredPath || '/', { replace: true });
        return true;
      } catch (error) {
        console.tag(tag).error(error);
        setStatus(LOADING_STATUS.hasFailed);
        displayErrorMessage(error);
        return false;
      }
    },
    [
      acceptedCookies,
      acceptsProtection,
      displayErrorMessage,
      email,
      familyName,
      givenName,
      password,
      repeatPw,
      setMessage,
      setStatus,
      setType,
      setUser,
    ]
  );

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Stack gap="20px">
          <Stack margin="0 0 10px 0">
            <h1>Konto erstellen</h1>
          </Stack>
          <input
            type="text"
            autoComplete="given-name"
            aria-label="Vorname"
            placeholder="Vorname"
            size="40"
            value={givenName}
            onChange={handleGivenNameChange}
            title="Bitte geben Sie Ihren Vornamen oder einen Spitznamen an."
            required
          />
          <input
            type="text"
            autoComplete="family-name"
            aria-label="Nachname"
            placeholder="Nachname"
            size="40"
            value={familyName}
            onChange={handleFamilyNameChange}
            title="Bitte geben Sie Ihren Nachnamen oder einen Spitznamen an."
            required
          />
          <input
            type="email"
            autoComplete="email"
            aria-label="Email-Adresse"
            placeholder="Email-Adresse"
            size="40"
            value={email}
            onChange={handleEmailChange}
            pattern=".{3,}"
            title="Bitte geben Sie Ihre Email-Adresse an."
            required
          />
          <input
            type="password"
            autoComplete="new-password"
            aria-label="Erstellen Sie ein Passwort"
            placeholder="Erstellen Sie ein Passwort"
            size="40"
            value={password}
            onChange={handlePasswordChange}
            pattern=".{1,}"
            title="Bitte erstellen Sie ein Passwort, um Ihr Konto zu sichern."
            required
          />
          <input
            type="password"
            autoComplete="new-password"
            aria-label="Bitte wiederholen Sie Ihr Passwort"
            placeholder="Bitte wiederholen Sie Ihr Passwort"
            size="40"
            value={repeatPw}
            onChange={handleRepeatPwChange}
            pattern=".{1,}"
            title="Bitte wiederholen Sie Ihr Passwort, um Tippfehler zu überprüfen."
            required
          />
          <Checkbox
            id="protection-checkbox"
            label={
              <>
                {'Ich stimme dem '}
                <CustomLink link="/protection" newTab>
                  Datenschutz
                </CustomLink>
                {' zu.'}
              </>
            }
            onChange={handleCheckboxChange}
            checked={acceptsProtection}
            required
            alignLeft
          />
          <Button label="Konto erstellen" isLoading={isLoading} submit />
        </Stack>
      </Form>
    </>
  );
};

export default Signup;
